import { Box, Text } from "@chakra-ui/react";

const NoPage = () => {
  return (
    <Box>
      <Text>Page could not be found!</Text>
    </Box>
  )
}

export default NoPage